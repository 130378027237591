<template>
  <div class="project-create">
    <div class="manage-wrapper">
      <div class="project-create-container">
        <div class="create-wrap">
          <el-form ref="form" :model="form" label-width="120px" label-position="left">
            <el-form-item label="问卷类型">
              <div class="types flex-align-between">
                <div class="type-item flex-align-between" :class="{ 'type-item-active': currentIndex == index }"
                  v-for="(item, index) in typeList" :key="index" @click="selectType(index, item)">
                  <div class="info flex-column">
                    <span>{{ item.label }}</span>
                    <span>{{ item.introduce1 }}</span>
                    <span>{{ item.introduce2 }}</span>
                  </div>
                  <div class="line"></div>
                  <div class="logo">
                    <img :src="item.imgUrl" alt="">
                  </div>
                </div>
                <div class="type-item2 flex-align-between">
                </div>
              </div>
            </el-form-item>
            <el-form-item>
              <el-checkbox v-model="form.syyqChecked">双优云桥</el-checkbox>
            </el-form-item>
            <el-form-item label="选择用户组">
              <el-cascader :options="syyqGroupOption" v-model="form.syyquserGroupIds"
                :props="{ multiple: true, value: 'id', emitPath: false }"></el-cascader>
            </el-form-item>
            <el-form-item>
              <el-checkbox v-model="form.ssyzChecked">首师优字</el-checkbox>
            </el-form-item>
            <el-form-item label="发布给">
              <el-radio-group v-model="form.resource" @change="selectResource">
                <el-radio label="2">按学校</el-radio>
                <el-radio label="3">按年级</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="选择用户组">
              <el-cascader :options="ssyzGroupOption" v-model="form.ssyzuserGroupIds"
                :props="{ multiple: true, value: 'id', label: 'name' }"></el-cascader>
            </el-form-item>
            <el-form-item label="显示平台">
              <el-checkbox-group v-model="form.showType">
                <el-checkbox label="1">首师优字平台</el-checkbox>
                <el-checkbox label="2">首师优字小程序</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit">{{this.$route.query.type == 'edit'?'编辑问卷':'立即创建'}}</el-button>
              <el-button @click="onCancel">取消</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  mapState
} from "vuex";
export default {
  data() {
    return {
      currentIndex: 0,
      typeList: [],
      form: {
        syyqChecked: false, // 双优云桥是否选中
        ssyzChecked: false, // 首师优字是否选中
        type: 1,
        syyquserGroupIds: [], // 双优云桥选定用户组
        ssyzuserGroupIds: [], // 首师优字选定用户组
        showType: [], // 显示平台 1首师优字 2首师优字小程序
        resource: '2',
      },
      syyqGroupOption: [], // 双优云桥用户组
      ssyzGroupOption: [], // 首师优字用户组
      ssyzAllGroupOption: {}
    };
  },
  computed: {
    ...mapState({
      currentProject: state => state.user.currentProject.id,
    })
  },
  methods: {
    selectType(index, item) {
      if (this.$route.query.type == 'edit') {
        this.$message({
          message: '不允许修改问卷类型',
          type: 'warning'
        });
      } else {
        this.currentIndex = index;
        this.form.type = item.id;
      }
    },
    // 获取问卷星类型
    async atypeList() {
      let resData = await this.$Api.wjx.atypeList();
      resData.data.map(item => {
        switch (item.id) {
          case 1:
            item.introduce1 = '丰富题型，强大逻辑';
            item.introduce2 = '问卷密码，红包抽奖';
            item.imgUrl = require('../../../assets/wjx/01.png')
            break;
          case 2:
            item.introduce1 = '题库抽题，限时作答';
            item.introduce2 = '成绩查询，自动阅卷';
            item.imgUrl = require('../../../assets/wjx/02.png')
            break;
          case 3:
            item.introduce1 = '图文视频，选项随机';
            item.introduce2 = '实时排行，微信投票';
            item.imgUrl = require('../../../assets/wjx/03.png')
            break;
          case 4:
            item.introduce1 = '信息登记，活动报名';
            item.introduce2 = '数据导入，流程审批';
            item.imgUrl = require('../../../assets/wjx/04.png')
            break;
          case 5:
            item.introduce1 = '批量评估，权重维度';
            item.introduce2 = '教学评估，人才盘点';
            item.imgUrl = require('../../../assets/wjx/05.png')
            break;
          case 6:
            item.introduce1 = '支持多级测评维度';
            item.introduce2 = '自动呈现测评报告';
            item.imgUrl = require('../../../assets/wjx/06.png')
            break;
          case 9:
            item.introduce1 = '预设名单，进度跟踪';
            item.introduce2 = '两码收集，作业收集';
            item.imgUrl = require('../../../assets/wjx/07.png')
            break;
          case 10:
            item.introduce1 = '支持评议规则设置';
            item.introduce2 = '自动进行人才分类';
            item.imgUrl = require('../../../assets/wjx/08.png')
            break;
        }
      })
      this.typeList = resData.data;
      console.log('问卷星类型:', resData);
    },
    // 获取双优云桥用户组选项
    async getGroupInfo() {
      let id = this.currentProject;
      let resData = await this.$Api.Course.GroupInfo(id);
      this.syyqGroupOption = resData.data;
    },
    // 获取首师优字用户组选项
    async getYzData() {
      let resData = await this.$Api.wjx.getYzData();
      // console.log('获取首师优字用户组选项:', resData);
      this.ssyzAllGroupOption = resData.data
      this.ssyzGroupOption = resData.data.school;
    },
    selectResource(val) {
      // console.log(val);
      if (val == 2) {
        this.ssyzGroupOption = this.ssyzAllGroupOption.school
      } else {
        this.ssyzGroupOption = this.ssyzAllGroupOption.grade
      }
    },
    async onSubmit() {
      let yzBindIds = [];
      this.form.ssyzuserGroupIds.map(item => {
        yzBindIds.push(item.join(","))
      })
      let data = {
        atype: this.form.type,
        bindType: this.form.resource,
        yxIsBind: this.form.syyqChecked ? 1 : 0,
        yzIsBind: this.form.ssyzChecked ? 1 : 0,
        yxBindIds: this.form.syyquserGroupIds,
        yzBindIds,
        showPlatform: this.form.showType.join(","),
        projectId: this.currentProject
      }
      let resData;
      if (this.$route.query.type == 'edit') {
        data.id = this.$route.query.id;
        resData = await this.$Api.wjx.editWjx(data);
        console.log('编辑问卷星问卷:', resData);
        if (resData.data.code == 200) {
          this.$router.push({
            path: '/salon/wjxIframe',
            query: {
              breadNumber: 5,
              iframeUrl: resData.data.data,
              breadcrumbName: '创建问卷'
            }
          })
        } else {
          this.$notify.error({
            title: '创建失败',
            message: '创建失败,请联系管理员'
          });
        }
      } else {
        resData = await this.$Api.wjx.createWjx(data);
        console.log('创建问卷星问卷:', resData);
        if (resData.code == 200) {
          this.$router.push({
            path: '/salon/wjxIframe',
            query: {
              breadNumber: 5,
              iframeUrl: resData.data,
              breadcrumbName: '创建问卷'
            }
          })
        } else {
          this.$notify.error({
            title: '创建失败',
            message: '创建失败,请联系管理员'
          });
        }
      }


    },
    // 获取单个问卷信息
    async getById(id) {
      let data = {
        id
      }
      let resData = await this.$Api.wjx.getById(data);
      console.log('当前问卷信息:', resData);
      this.$set(this.form, 'type', resData.data.atype);
      this.currentIndex = resData.data.atype - 1;
      this.form.syyqChecked = resData.data.yxIsBind == 1 ? true : false;
      this.form.ssyzChecked = resData.data.yzIsBind == 1 ? true : false;
      this.form.syyquserGroupIds = resData.data.yxBindIds;
      this.form.resource = resData.data.bindType.toString();

      try {
        if (resData.data.yzBindIds.length > 0) {
          this.$set(this.form, 'ssyzuserGroupIds', resData.data.yzBindIds.map(item => item.split(',').map(Number)))
        }
      } catch (err) {
        console.log(err)
      }
      if (resData.data.showPlatform) {
        this.form.showType = resData.data.showPlatform.split(',')
      };
      let resData2 = await this.$Api.wjx.getYzData();
      console.log('获取首师优字用户组选项:', resData);
      this.ssyzAllGroupOption = resData2.data;
      this.selectResource(resData.data.bindType);

    },
    onCancel() {
      this.$router.go(-1)
    }
  },
  mounted() {
    this.atypeList();
    this.getGroupInfo();
    if (this.$route.query.type == 'edit') {
      this.$route.meta.title = '编辑问卷';
      this.getById(this.$route.query.id);
    } else {
      this.getYzData();
    }
  }
};
</script>
  
<style lang="less" scoped>
@import "./less/wjx_create.less";
</style>